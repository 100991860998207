<template>
<div id="Magic">
  <canvas id="canvas_magic"></canvas>
  <div id="text">
    <h1>Magic</h1>
  </div>
</div>
</template>

<script>
import { onMounted } from 'vue';

export default {
  name: 'Magic',
  setup () {
    onMounted(() => {
      let container = document.getElementById('Magic');
      const canvasBody = document.getElementById('canvas_magic');
      const drawArea = canvasBody.getContext('2d');
      let delay = 200;
      let time;
      let w;
      let h;
      const opts = {
        particleColor: 'white',
        lineColor: 'rgb(255,255,255)',
        particleAmount: 30,
        defaultSpeed: 1,
        variantSpeed: 1,
        defaultRadius: 2,
        variantRadius: 3,
        linkRadius: 200,
      };
      let rgb = opts.lineColor.match(/\d+/g);

      let resizeReset = function () {
        w = canvasBody.width = container.offsetWidth;
        h = canvasBody.height = container.offsetHeight;
      };


      window.addEventListener('resize', function () {
        deBouncer();
      });


      let deBouncer = function () {
        clearTimeout(time);
        time = setTimeout(() => {
          resizeReset();
        }, delay);
      };


      let checkDistance = function (x1, y1, x2, y2) {
        return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
      };


      let linkPoints = function (point1, hubs) {
        for (let i = 0; i < hubs.length; i++) {
          let distance = checkDistance(point1.x, point1.y, hubs[i].x, hubs[i].y);
          let opacity = 1 - distance / opts.linkRadius;
          if (opacity > 0) {
            drawArea.lineWidth = 0.5;
            drawArea.strokeStyle = `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${opacity})`;
            drawArea.beginPath();
            drawArea.moveTo(point1.x, point1.y);
            drawArea.lineTo(hubs[i].x, hubs[i].y);
            drawArea.closePath();
            drawArea.stroke();
          }
        }
      };


      function Particle () {
        this.x = Math.random() * w;
        this.y = Math.random() * h;
        this.speed = opts.defaultSpeed + Math.random() * opts.variantSpeed;
        this.directionAngle = Math.floor(Math.random() * 360);
        this.color = opts.particleColor;
        this.radius = opts.defaultRadius + Math.random() * opts.variantRadius;
        this.vector = {
          x: Math.cos(this.directionAngle) * this.speed,
          y: Math.sin(this.directionAngle) * this.speed,
        };
        this.update = function () {
          this.border();
          this.x += this.vector.x;
          this.y += this.vector.y;
        };
        this.border = function () {
          if (this.x >= w || this.x <= 0) {
            this.vector.x *= -1;
          }
          if (this.y >= h || this.y <= 0) {
            this.vector.y *= -1;
          }
          if (this.x > w) this.x = w;
          if (this.y > h) this.y = h;
          if (this.x < 0) this.x = 0;
          if (this.y < 0) this.y = 0;
        };
        this.draw = function () {
          drawArea.beginPath();
          drawArea.arc(this.x, this.y, this.radius, 0, Math.PI * 2);
          drawArea.closePath();
          drawArea.fillStyle = this.color;
          drawArea.fill();
        };
      }


      /////////////////////////////////////
      let particles;

      function setupCanvas () {
        particles = [];
        resizeReset();
        for (let i = 0; i < opts.particleAmount; i++) {
          particles.push(new Particle());
        }
        animationLoop();
      }


      function animationLoop () {
        drawArea.clearRect(0, 0, w, h);
        for (let i = 0; i < particles.length; i++) {
          particles[i].update();
          particles[i].draw();
        }
        for (let i = 0; i < particles.length; i++) {
          linkPoints(particles[i], particles);
        }

        let magicDOM = document.getElementById('Magic');
        if (magicDOM) requestAnimationFrame(animationLoop);
      }

      setupCanvas();
    });
  },
};
</script>

<style lang="stylus" scoped>
#Magic
  border-radius 20px
  position absolute
  top 70px
  right 70px
  bottom 70px
  left 70px
  display flex
  align-items center
  justify-content center
  border 2px dashed rgba(255, 255, 255, 0.2)
  #canvas_magic, #text
    position absolute;
    display block;
    top 0
    left 0
    right 0
    bottom 0
    z-index 10;
  #text
    font 500 100px Averta
    z-index 100
    color #fff
    text-transform uppercase
    font-size 25px
    display flex
    align-items center
    justify-content center
    text-align center
</style>